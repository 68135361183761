import React from 'react';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Stack, TextField, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { DateTime } from 'luxon';
import { Loader } from '@googlemaps/js-api-loader';

class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date().toISOString(),
            isLoading: false,
            isErrored: false,
            errorTitle: "",
            errorText: "",
            locationMode: props.locationMode,
            manLocLocationName: props.manLocLocationName,
            manLocLocationValue: props.manLocLocationValue,
            manLocLocationLat: props.manLocLocationLat,
            manLocLocationLng: props.manLocLocationLng
        }

        this.autocompleteWidget = undefined;
    }

    handleDateChange = (newValue) => {
        this.setState({date: newValue})
    }

    updateLocationManualValue = (newName, newValue, newLat, newLng) => {
        console.log(newValue)
        this.setState({manLocLocationName: newName, manLocLocationValue: newValue, manLocLocationLat: newLat, manLocLocationLng: newLng})
        this.props.updateManLoc(newName, newValue, newLat, newLng)
    }

    handleLocationModeChange = (event, newValue) => {
        this.setState({locationMode: newValue})
        this.props.updateLocationMode(newValue)
    }

    handleSubmit = () => {
        this.setState({isLoading: true})
        let posx;
        let posy;
        let elevation;

        if (this.state.locationMode === "curloc") {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    posx = position.coords.latitude
                    posy = position.coords.longitude
                    elevation = position.coords.altitude
                    if (elevation == null) {
                        elevation = 0.0
                    }
                    this.handleFetch(posx, posy, elevation)
                }, (error) => {
                    this.handleGeoFailure(error)
                })
            }
        } else if (this.state.locationMode === "manloc") {
            const place = this.autocompleteWidget.getPlace()
            if (!place || !place.geometry || !place.geometry.location) {
                this.setState({isLoading: false,
                               isErrored: true,
                               errorTitle: "Unable to generate SkyCast - No Coordinates",
                               errorText: "A SkyCast couldn't be generated since the location you entered doesn't have any coordinates attached to it. Make sure you click on the suggested location, or you may need to try a different location."})
            }
            posx = place.geometry.location.lat()
            posy = place.geometry.location.lng()
            elevation = 0.0
            // Switch to manloc_prev anytime we use manloc
            this.handleFetch(posx, posy, elevation)
        } else if (this.state.locationMode === "manloc_prev") {
            posx = this.state.manLocLocationLat
            posy = this.state.manLocLocationLng
            elevation = 0.0
            this.handleFetch(posx, posy, elevation)
        }
    }

    handleFetch = (posx, posy, elevation) => {
        let input_ts = DateTime.fromISO(this.state.date).setZone("local").startOf("day").valueOf() / 1000

        // Handle shizz here
        fetch(`https://skycast.owenthe.dev/api/v1/getSunCast?lat=${posx}&lng=${posy}&elevation=${elevation}&ts=${input_ts}`)
        .then(response => {
            // Passing stringified JSON as the exception to tell the user when they can request again? hell yes. I am a good developer.
            if (response.status === 429) {
                throw Error(JSON.stringify({"error": "RL", "rl-expire": response.headers.get("X-RateLimit-Reset")}))
            } else if (response.status === 400) {
                throw Error(JSON.stringify({"error": "NODATA"}))
            } else if (response.status === 404) {
                throw Error(JSON.stringify({"error": "BADDATE"}))
            } else if (response.status === 500) {
                throw Error(JSON.stringify({"error": "ISE"}))
            } else if (!response.ok) {
                throw Error(JSON.stringify({"error": response.statusText}))
            }

            return response
        })
        .then(response => response.json())
        .then(response => {
            if (this.state.locationMode === "manloc") {
                this.handleLocationModeChange(undefined, "manloc_prev")
                const place = this.autocompleteWidget.getPlace()
                this.updateLocationManualValue(place.name, document.getElementById("gmaps-autocomplete-field").value, posx, posy)
            } else if (this.state.locationMode === "curloc") {
                this.handleLocationModeChange(undefined, "curloc")
            }

            this.setState({isLoading: false})
            this.props.dataCallback(response)
            // Scroll to top of page when the process is done
            window.scrollTo(0, 1)
        })
        .catch(error => {
            try {
                error = JSON.parse(error.message)
                if (error['error'] === "RL") {
                    let rlreset = DateTime.fromSeconds(parseInt(error['rl-expire'])).toFormat("t")
                    this.setState({isLoading: false, isErrored: true, errorTitle: "You're being rate limited", errorText: `You've requested too many predictions from SkyCast in the last hour. You can start requesting predictions again at ${rlreset}.`})
                } else if (error['error'] === "NODATA") {
                    this.setState({isLoading: false, isErrored: true, errorTitle: "Unable to generate SkyCast - No Sunrise/Sunset", errorText: "A SkyCast couldn't be generated for this date because a sunrise/sunset did not occur at the location selected."})
                } else if (error['error'] === "BADDATE") {
                    this.setState({isLoading: false, isErrored: true, errorTitle: "Unable to generate SkyCast - Date Invalid", errorText: "A SkyCast couldn't be generated for the date provided because it's too far in the future or too far in the past."})
                } else if (error['error'] === "ISE") {
                    this.setState({isLoading: false, isErrored: true, errorTitle: "Unable to generate SkyCast - Server Error", errorText: "A SkyCast couldn't be generated because the server returned an Internal Server Error, or the WeatherKit API returned a 500 error."})
                } else {
                    this.setState({isLoading: false, isErrored: true, errorTitle: "Failed to retrive SkyCast data", errorText: `Failed to query the SkyCast server. The error returned is ${error['error']}`})
                }
            } catch (e) {
                this.setState({isLoading: false, isErrored: true, errorTitle: "Unable to generate SkyCast", errorText: `An error occurred while trying to generate a SkyCast: ${error}`})
            }
        })
    }

    handleGeoFailure = (error) => {
        let errorTitle;
        let errorText;

        switch (error.code) {
            case error.PERMISSION_DENIED:
                errorTitle = "Unable to generate SkyCast - Location Denied"
                errorText = "A SkyCast couldn't be generated since you denied location permissions. Please reenable location permissions then try again."
                break;
            case error.POSITION_UNAVAILABLE:
                errorTitle = "Failed to generate SkyCast - Location Unavailable"
                errorText = "A SkyCast couldn't be generated since your location is unavailable. Move to an area with better GPS reception, then try again."
                break;
            case error.TIMEOUT:
                errorTitle = "Failed to generate SkyCast - Location Timeout"
                errorText = "A SkyCast couldn't be generated since the query to get your location timed out. Accept any location permission prompts, move to an area with better GPS reception, then try again."
                break;
            case error.UNKNOWN_ERROR:
                errorTitle = "Failed to generate SkyCast - Unknown Location Error"
                errorText = "A SkyCast couldn't be generated since the query to get your location ran into an unknown error. Accept any location permission prompts, move to an area with GPS reception, restart your device, then try again."
                break;
            default:
                errorTitle = "Failed to generate SkyCast - Unknwon Location Error"
                errorText = "A SkyCast couldn't be generated since the query to get your location ran into an unknown error. Accept any location permission prompts, move to an area with GPS reception, restart your device, then try again."
                break;
        }

        this.setState({isLoading: false, isErrored: true, errorTitle: errorTitle, errorText: errorText})
    }

    handleErrorClose = () => {
        this.setState({isErrored: false})
    }

    componentDidMount = () => {
        const loader = new Loader({
            apiKey: process.env.REACT_APP_GMAPS_API_KEY,
            version: "quarterly"
        })

        loader.importLibrary('places').then(({Autocomplete}) => {
            const options = {
                componentRestrictions: {country: "us"},
                fields: ["address_components", "geometry", "icon", "name"],
                strictBounds: false
            }
            this.autocompleteWidget = new Autocomplete(document.getElementById("gmaps-autocomplete-field"), options)
        })
    }

    render() {
        return (
            <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ pt: 1, pb: 4 }}>
                <FormControl alignItems="center" justifyContent="center">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <MobileDatePicker
                            label="Forecast Date"
                            inputFormat="MM/dd/yyyy"
                            value={this.state.date}
                            onChange={this.handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <FormLabel id="locationmethod-label" sx={{ pt: 2 }}>Forecast location</FormLabel>
                    <RadioGroup aria-labelledby="locationmethod-label" defaultValue="curloc" name="radio-buttons-group" value={this.state.locationMode} onChange={this.handleLocationModeChange}>
                        <FormControlLabel value="curloc" control={<Radio />} label="Use your current location" />
                        {this.state.manLocLocationValue && this.props.renderContext !== "onboarding" ? <FormControlLabel value="manloc_prev" control={<Radio />} label={"Use selected location: " + this.state.manLocLocationName} /> : ""}
                        <FormControlLabel value="manloc" control={<Radio />} label="Choose a location" />
                        <div style={{display: this.state.locationMode === "manloc" ? "block" : "none"}}>
                            <TextField id="gmaps-autocomplete-field" variant="outlined" label="Forecast location" defaultValue={this.state.manLocLocationValue} sx={{ width: "100%", mt: 1 }}></TextField>
                        </div>
                    </RadioGroup>
                </FormControl>
                <Button variant="contained" disabled={this.state.isLoading} onClick={this.handleSubmit}>Submit</Button>
                {this.state.isLoading && (
                    <>
                        <CircularProgress />
                        <Typography variant="body1" component="div">
                            It'll take a moment to get your location and generate the SkyCast - please wait!
                        </Typography>
                    </>
                )}
                <Dialog
                    open={this.state.isErrored}
                    onClose={this.handleErrorClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {this.state.errorTitle}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.errorText}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleErrorClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Stack>
        )
    }
}

export default DatePicker