import Box from '@mui/material/Box';
import React from 'react';
import { Stack, Typography, Container, Link } from '@mui/material';
import DatePicker from './DatePicker';
import Header from './Header';

class Onboarding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date().toISOString(),
            isLoading: false,
            isErrored: false
        }
    }

    handleDateChange(newValue) {
        this.setState({date: newValue})
    }    



    render() {
        return (
            <Box sx={{ flexGrow: 1}}>
                <Header />
                <Container maxWidth="md">
                    <Stack spacing={2} alignItems="center" justifyContent="center">
                        <Typography variant="h4" component="div">
                            Welcome to SkyCast!
                        </Typography>
                        <Typography variant="body1" component="div" align="center">
                            SkyCast is a sunrise/sunset quality predictor powered by <Link href="https://weatherkit.apple.com/legal-attribution.html" rel="noopener" target="_blank">Apple Weather</Link><br></br>
                            To get started, enter a date below to get a prediction for your current location.
                        </Typography>
                        <DatePicker 
                            dataCallback={this.props.dataCallback} 
                            locationMode={this.props.locationMode}
                            renderContext={"onboarding"}
                            manLocLocationValue={this.props.manLocLocationValue}
                            manLocLocationLat={this.props.manLocLocationLat}
                            manLocLocationLng={this.props.manLocLocationLng}
                            updateLocationMode={this.props.updateLocationMode}
                            updateManLoc={this.props.updateManLoc} />
                    </Stack>
                </Container>
            </Box>
        )
    }
}

export default Onboarding