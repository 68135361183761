import React from 'react';
import Box from '@mui/material/Box';
import { Stack, Typography, Container, Grid, Alert } from '@mui/material';
import PredictionCard from './PredictionCard';
import DatePicker from './DatePicker';
import Header from './Header';
import { DateTime } from 'luxon';

class Prediction extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <Box>
                <Header />
                <Container maxWidth="md">
                    <Stack spacing={2} alignItems="center" justifyContent="center">
                        <Typography variant="h5" component="div">
                            Here's your SkyCast {this.props.locationMode === "manloc_prev" ? `for ${this.props.manLocLocationName} on ` : "for "} {DateTime.fromSeconds(this.props.data['data']['sunrise']['time']).toFormat("DDD")}!
                        </Typography>
                        <Grid container columns={{ xs: 2, sm: 4, md: 4}} rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={2} sx={{ padding: 2 }}>
                                <PredictionCard data={this.props.data['data']['sunrise']} operation={"sunrise"} operationFormatted={"Sunrise"} />
                            </Grid>
                            <Grid item xs={2} sx={{ padding: 2 }}>
                                <PredictionCard data={this.props.data['data']['sunset']} operation={"sunset"} operationFormatted={"Sunset"} />
                            </Grid>
                        </Grid>
                        <Typography variant="caption" component="div">
                            SkyCast generated on {DateTime.fromSeconds(this.props.data['generatedTime']).toFormat("f")} using algorithm v{this.props.data['algorithmVersion']}
                        </Typography>
                        <Alert severity="info"><b>Tip: </b>Depending on cloud cover, the best color is produced just before sunrise and just after sunset. SkyCasts are most accurate a couple hours before sunrise/sunset.</Alert>
                        <Typography sx={{ pt: 3 }} variant="body1" component="div">
                            If you'd like to generate a SkyCast for another date or location, modify the details below.
                        </Typography>
                        <DatePicker 
                            dataCallback={this.props.dataCallback} 
                            locationMode={this.props.locationMode}
                            renderContext={"prediction"}
                            manLocLocationName={this.props.manLocLocationName}
                            manLocLocationValue={this.props.manLocLocationValue}
                            manLocLocationLat={this.props.manLocLocationLat}
                            manLocLocationLng={this.props.manLocLocationLng}
                            updateLocationMode={this.props.updateLocationMode}
                            updateManLoc={this.props.updateManLoc} />
                    </Stack>
                </Container>
            </Box>
        )
    }
}

export default Prediction