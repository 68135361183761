import { Card, CardContent, Typography, Collapse, Button, CardActions, Divider, CircularProgress, Box } from '@mui/material';
import React from 'react';
import { DateTime } from 'luxon';
import { styled } from '@mui/material/styles';
import { ExpandMore, Info } from '@mui/icons-material/'

const ExpandMoreProp = styled((props) => {
    const { expand, ...other } = props;
    return <ExpandMore {...other}/>;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: "0.5rem",
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

class PredictionCard extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            data: {},
            expanded: false
        }

        let data = this.props.data
        this.summarytext = this.summary(data['totalScore'], this.props.operation)
        this.timing = DateTime.fromSeconds(data['time']).toFormat("t")
    }

    summary = (score, operation) => {
        if (score < 0.05) {
            return `The ${operation} will not be visible on this day :(`
        } else if (score < 0.15) {
            return `There's a low chance of a visible ${operation}. But keep your eyes peeled, the weather might do something crazy!`
        } else if (score < 0.30) {
            return `A modest ${operation} should be visible due to high cloud cover. However, high cloud cover ${operation}s can produce beautiful looking ones - so take a look if possible!`
        } else if (score < 0.50) {
            return `A decent ${operation} should be visible, due to no/low or high cloud cover. If the cloud cover is high, a beautiful ${operation} may be visible!`
        } else if (score < 0.65) {
            return `A good ${operation} should be visible from sufficient cloud cover. A couple color flares on clouds should be visible!`
        } else if (score < 0.75) {
            return `A great ${operation} should be visible. Some color flares on clouds will be visible, with some dramatic skies possible.`
        } else if (score < 0.85) {
            return `A really good ${operation} should be visible. Expect good color flares on clouds and potentially some dramatic skies!`
        } else if (score < 0.95) {
            return `An amazing ${operation} should be visible! The cloud cover is just right for loads of color flares and dramatic skies.`
        } else {
            return `A magnificant ${operation} should be visible. Expect amazing color flares, dramatic skies, and a ${operation} to blow your socks off.`
        }
    }

    handleExpandClick = () => {
        this.setState({expanded: !this.state.expanded})
    }

    handleColoredPercentage = (pct) => {
        if (pct < 0.15) {
            return (theme) => theme.palette.error.light
        } else if (pct < 0.35) {
            return (theme) => theme.palette.warning.light
        } else if (pct < 0.65) {
            return (theme) => theme.palette.info.light
        } else {
            return (theme) => theme.palette.success.light
        }
    }

    render() {
        return (
            <Card raised={true} >
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {this.props.operationFormatted}
                    </Typography>
                    <Box sx={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'center'}}>
                        <Box sx={{ position: 'relative', marginRight: 1, width: "24px", height: "24px" }}>
                            <CircularProgress variant="determinate" sx={{ color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800] }} value={100} size={24} thickness={5} />
                            <CircularProgress variant="determinate" sx={{ color: this.handleColoredPercentage(this.props.data['totalScore']), position: 'absolute', left: 0 }} value={Math.round(this.props.data['totalScore'] * 100)} size={24} thickness={5}/>
                        </Box>
                        <Typography variant="h5" display="inline" alignItems="center" alignContent="center">
                            {Math.round(this.props.data['totalScore'] * 100)}% score
                        </Typography>
                    </Box>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        from {this.props.data['ghStart'] === -864000 ? "N/A" : DateTime.fromSeconds(this.props.data['ghStart']).toFormat("t")} to {this.props.data['ghEnd'] === -864000 ? "N/A" : DateTime.fromSeconds(this.props.data['ghEnd']).toFormat("t")}
                        <Typography sx={{ fontSize: 14 }} color="text.secondary">
                            ({this.props.operation} at {DateTime.fromSeconds(this.props.data['time']).toFormat("t")})
                        </Typography>
                    </Typography>
                    <Typography variant="body2">
                        {this.summary(this.props.data['totalScore'], this.props.operation)}
                    </Typography>
                    {this.props.data['temperature'] >= 89.5 &&
                        <Typography variant="body2" sx={{ verticalAlign: 'middle', display: 'inline-flex', marginTop: 1}}>
                            <Info fontSize="small" />&nbsp;The temperature for the {this.props.operation} will be above 90°F. Stay cool and hydrated while watching the {this.props.operation}!
                        </Typography>
                    }
                    {this.props.data['temperature'] <= 19.5 &&
                        <Typography variant="body2" sx={{ verticalAlign: 'middle', display: 'inline-flex', marginTop: 1}}>
                            <Info fontSize="small" />&nbsp;The temperature for the {this.props.operation} will be below 20°F. Dress well and stay warm while watching the {this.props.operation}!
                        </Typography>
                    }
                </CardContent>
                <CardActions disableSpacing>
                    <Button variant="outlined" sx={{ marginLeft: 'auto', padding: "5px 8px 5px 15px" }} onClick={this.handleExpandClick}>
                        <Typography variant="button" color="text.secondary" sx={{ marginLeft: 'auto' }}>
                            {this.state.expanded ? "Hide details" : "Show details"}
                        </Typography>
                        <ExpandMoreProp expand={this.state.expanded} />
                    </Button>
                </CardActions>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            Here's the details that came together to make this SkyCast:
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                            Temperature: {this.props.data['temperature'].toFixed(0)}°F (feels like {this.props.data['feelsLike'].toFixed(0)}°F) <br></br>
                            Cloud Cover: ~{(this.props.data['cloudCover'] * 100).toFixed(2)}% (+ {(this.props.data['cloudCoverScoreRaw'] * 100).toFixed(2)}% score) <br></br>
                            Cloud Cover Delta: {(this.props.data['cloudCoverCenter'] * 100).toFixed(2)}% here -&gt; {(this.props.data['cloudCoverOffset'] * 100).toFixed(2)}% in distance = {(this.props.data['cloudCoverDeltaRaw'] * 100).toFixed(2)}% delta ({this.props.data['cloudCoverDelta'] >= 0 ? "+ " : "- "} {(Math.abs(this.props.data['cloudCoverDelta'] * 100)).toFixed(2)}% score) <br></br>  
                            Humidity: {(this.props.data['humidity'] * 100).toFixed(2)}% (+ {(this.props.data['humidityScore'] * 100).toFixed(2)}% score)<br></br>
                            Base Score: {(this.props.data['baseScore'] * 100).toFixed(2)}%
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                            Wind: {(this.props.data['wind']).toFixed(2)} mph (+ {(this.props.data['windScore'] * 100).toFixed(2)}% score)<br></br>
                            Visibility: {this.props.data['visibility'] === 10 ? "10+ ": this.props.data['visibility'].toFixed(1)} mi (- {(Math.abs(this.props.data['visibilityScore'] * 100)).toFixed(2)}% score)<br></br>
                            Bonus Score: {((this.props.data['windScore'] + this.props.data['visibilityScore']) * 100).toFixed(2)}%
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                            Total Score: {(this.props.data['totalScore'] * 100).toFixed(2)}%
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="caption" color="text.secondary" sx={{ lineHeight: 1.0 }}>
                            Cloud Cover Delta points are awarded or taken away when there's a 15%+ difference in cloud cover between your location and the horizon. Points are awarded for setting/rising into/from less clouds, deducted for more clouds.
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        )
    }
}

export default PredictionCard