import './App.css';
import React from 'react';
import Prediction from './Prediction';
import Onboarding from './Onboarding'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      haveData: false,
      data: {}
    }

    // DatePicker so happens to handle the location picker, but is spread across onboarding and prediction.
    // So we have to store its data up here so it can save state between the changes.
    // This is just poor design of React but SkyCast was the first React project I made so here you go whoopee
    this.locationMode = "curloc"
    this.manLocLocationName = undefined
    this.manLocLocationValue = undefined
    this.manLocLocationLat = undefined
    this.manLocLocationLng = undefined
  }

  processdata = (data) => {
    this.setState({haveData: true, data: data})
  }

  updateLocationMode = (newMode) => {
    this.locationMode = newMode
  }

  updateManLoc = (newName, newValue, newLat, newLng) => {
    this.manLocLocationName = newName
    this.manLocLocationValue = newValue
    this.manLocLocationLat = newLat
    this.manLocLocationLng = newLng
  }

  render() {
    if (this.state.haveData) {
      return (
        <Prediction 
          data={this.state.data} 
          dataCallback={this.processdata} 
          locationMode={this.locationMode}
          manLocLocationName={this.manLocLocationName}
          manLocLocationValue={this.manLocLocationValue}
          manLocLocationLat={this.manLocLocationLat}
          manLocLocationLng={this.manLocLocationLng}
          updateLocationMode={this.updateLocationMode} 
          updateManLoc={this.updateManLoc} />
      )
    } else {
      return (
        <Onboarding 
          dataCallback={this.processdata}
          locationMode={this.locationMode}
          manLocLocationName={this.manLocLocationName}
          manLocLocationValue={this.manLocLocationValue}
          manLocLocationLat={this.manLocLocationLat}
          manLocLocationLng={this.manLocLocationLng}
          updateLocationMode={this.updateLocationMode}
          updateManLoc={this.updateManLoc} />
      )
    }
  }
}

export default App;
