import React from 'react';
import {AppBar, Typography, Link} from '@mui/material'

class Header extends React.Component {
    render() {
        return (
            <AppBar position="static" sx={{ marginBottom: 5 }}>
                    <Typography variant="h6" display="inline" sx={{ flexGrow: 1, padding: 2, marginLeft: 1}}>
                        <Link href="/" underline="hover" variant="h6" color="inherit">
                            SkyCast
                        </Link>
                        <Typography variant="caption" display="inline" sx={{ paddingLeft: 1 }}>
                            v1.7.1
                        </Typography>
                    </Typography>
            </AppBar>
        ) 
    }
}

export default Header